var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tasq)?_c('div',{staticClass:"tasq-item grid gap-y-2 tasq-background-unselected",on:{"click":_vm.goToTasqPage}},[_c('div',{staticStyle:{"padding-top":"3px","padding-bottom":"0px"}},[_c('div',{staticStyle:{"float":"left","width":"100%","margin-bottom":"0px","padding-bottom":"0px"}},[_c('div',{staticStyle:{"width":"100%","overflow":"hidden"}},[_c('div',{staticStyle:{"float":"left"}},[_c('span',{staticClass:"badge-tasq bg-red-500",class:[_vm.tasq.bgColorClass],staticStyle:{"float":"left","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.tasq.overriddenName)+" ")]),_c('p',{staticStyle:{"position":"relative","clear":"both","margin-top":"5px","max-width":"calc(100%)","max-height":"20px","overflow":"hidden","float":"left","font-size":"14px","color":"rgba(255,255,255,0.9)","font-weight":"500","margin-bottom":"0px","padding-bottom":"0px"}},[_vm._v(" "+_vm._s(_vm.tasq.wellName)+" ")])]),(_vm.tasq.dateAgoText)?_c('div',{staticClass:"grid grid-flow-col gap-x-2 items-center truncated-text",staticStyle:{"float":"right","font-size":"12px","opacity":"0.65","margin-top":"0px"}},[_c('span',{staticClass:"material-icons",staticStyle:{"font-size":"16px","opacity":"0.9"}},[_vm._v(" watch_later ")]),_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.tasq.dateAgoText)+" ")])]):_vm._e()]),_c('div',{staticClass:"font-light",staticStyle:{"font-size":"11px","font-weight":"300","opacity":"0.7","margin-top":"5px","margin-bottom":"3px","padding-top":"0px","float":"left","clear":"both"}},[(_vm.tasq.defermentValue)?_c('span',[_vm._v(" -"+_vm._s(Math.round(_vm.tasq.defermentValue))+" Mcf/day ")]):_vm._e()])])]),_c('div',{staticClass:"grid gap-x-1.5 items-center grid-flow-col justify-between"},[_c('div',{staticClass:"grid gap-x-1.5 grid-flow-col items-center"},[_c('div',{staticClass:"tasq-item__dot tasq-item__dot--first",class:{
            'tasq-item__dot--completed': _vm.tasq.stepOneCompleted,
          }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--second",class:{
            'tasq-item__dot--completed': _vm.tasq.stepTwoCompleted,
          }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--third",class:{
            'tasq-item__dot--completed': _vm.tasq.stepThreeCompleted,
          }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--fourth",class:{
            'tasq-item__dot--completed': _vm.tasq.stepFourCompleted,
          }}),_c('span',{staticClass:"text-textgray text-xs"},[_vm._v(" "+_vm._s(_vm.tasq.stepLabel)+" ")])]),_c('div',{staticClass:"text-xs truncated-text"},[_c('p',{staticClass:"truncated-text",staticStyle:{"color":"rgba(255,255,255,0.8)"}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }